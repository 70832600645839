import { useEffect, useMemo, useState } from 'react';
import { FailedInvoice, Invoice, SyncInvoiceResponse } from '../types/invoice';
import { Selection } from 'components/record-list-screen/types';
import {
  InvoicePagination,
  WingsXero
} from '../types/third-party-service-xero';
import { difference, upperFirst } from 'lodash';
import { LIMIT } from '../data/third-party-service-xero';
import { ErrorListProps } from '../components/error-list';
import { useModelActions } from '@rexlabs/model-generator';
import uiModel from 'data/models/custom/ui';

export const useInvoicesToSync = ({ wingsXero }: { wingsXero: WingsXero }) => {
  const { loadingIndicatorOn, loadingIndicatorOff } = useModelActions(
    uiModel
  ) as any;

  const [invoicesSelection, setInvoicesSelection] = useState<Selection>({
    type: 'include',
    ids: []
  });
  const [ignoredInvoices, setIgnoredInvoices] = useState<string[]>([]);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [apiError, setApiError] = useState<Error | null>(null);
  const [syncErrors, setSyncErrors] = useState<ErrorListProps['errors']>([]);
  const [page, setPage] = useState(0);
  const [invoiceIds, setInvoiceIds] = useState<string[]>([]); // can also be Invoice['id'][]
  const [lastSynced, setLastSynced] = useState<string>();

  const invoicesPagination: InvoicePagination = useMemo(() => {
    return {
      recordIds: invoiceIds?.slice?.(page * LIMIT, page * LIMIT + LIMIT) || [],
      pagination: {
        totalItems: invoiceIds?.length,
        totalPages: Math.abs((invoiceIds?.length || 0) / LIMIT)
      },
      page,
      setPage
    };
  }, [invoiceIds, page]);

  const selectedInvoices = useMemo(() => {
    return invoicesSelection.type === 'include'
      ? invoicesSelection.ids
      : difference(invoiceIds, invoicesSelection.ids);
  }, [invoicesSelection, invoiceIds]);

  useEffect(() => {
    const fetchInvoices = async () => {
      if (!isLoading) {
        loadingIndicatorOn({ message: 'Loading...' });
      }
      try {
        if (invoiceIds.length > 0) {
          const res = (await wingsXero(
            {
              return_ids: false,
              invoice_ids: invoicesPagination.recordIds as string[]
            },
            'ThirdPartyServiceXero::getInvoicesToSync'
          )) as {
            invoices: Invoice[];
            last_synced: string;
          };
          setInvoices(res.invoices);
          setLastSynced(res.last_synced);
        }
      } catch (error) {
        setApiError(error as Error);
      }
      if (!isLoading) {
        loadingIndicatorOff();
      }
      setIsLoading(false);
    };
    fetchInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceIds, invoicesPagination.recordIds]);

  const getInvoices = async (refresh?: boolean) => {
    if (refresh) {
      setApiError(null);
      setSyncErrors([]);
      setInvoices([]);
      setInvoicesSelection({
        type: 'include',
        ids: []
      });
    }
    setIsLoading(true);
    try {
      const res = (await wingsXero(
        {
          return_ids: true,
          invoice_ids: []
        },
        'ThirdPartyServiceXero::getInvoicesToSync'
      )) as {
        invoices: string[];
      };

      setInvoiceIds(res.invoices as string[]);
      return res;
    } catch (error) {
      setIsLoading(false);
      setApiError(error as Error);
      return null;
    }
  };

  const syncInvoices = async () => {
    setSyncErrors([]);
    try {
      const res = (await wingsXero(
        {
          invoices_to_sync: selectedInvoices,
          invoices_to_ignore: ignoredInvoices
        },
        'ThirdPartyServiceXero::syncInvoicesToXero'
      )) as SyncInvoiceResponse;
      const { synced } = res;

      if (synced?.failed) {
        const formatted = synced.failed.map(
          (invoice: FailedInvoice) =>
            `${upperFirst(invoice.type)}-${invoice.id}: ${invoice.text}`
        );
        setSyncErrors((prev) => [...(prev || []), ...formatted]);
      }

      getInvoices();

      setInvoicesSelection({
        type: 'include',
        ids: []
      });

      setIgnoredInvoices([]);

      return res;
    } catch (error) {
      setSyncErrors((prev) => [...(prev || []), (error as Error).message]);
    }
    return null;
  };

  return {
    invoicesSelection,
    setInvoicesSelection,
    ignoredInvoices,
    setIgnoredInvoices,
    invoices,
    setInvoices,
    isLoading,
    setIsLoading,
    getInvoices,
    syncInvoices,
    selectedInvoices,
    apiError,
    invoicesPagination,
    syncErrors,
    lastSynced,
    invoiceIds
  };
};
