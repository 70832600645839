/* eslint-disable max-lines */
import React, { createContext, PureComponent } from 'react';
import dayjs from 'dayjs-ext'; // Extendable version of dayjs, for adding timezone support
import timeZone from 'dayjs-ext/plugin/timeZone';
import { autobind } from 'core-decorators';
import { withModel } from '@rexlabs/model-generator';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import moment from 'moment';
import { api } from 'shared/utils/api-client';

import {
  getClientTimezone,
  getTmpEventId,
  mapApiToEvents,
  mapEventToApi
} from 'utils/calendar';

import { CancelToken } from 'axios';

import uiModel from 'data/models/custom/ui';
import adminAppointmentTypesModel from 'features/calendar/data/admin-appointment-types';
import userCalendarsModel from 'data/models/entities/user-calendars';
import sessionModel from 'data/models/custom/session';
import calendarsModel from 'data/models/entities/calendars';
import apiCacheModel from 'data/models/custom/api-cache';

import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics';
import { withWhereabouts } from '@rexlabs/whereabouts';
import { flushSync } from 'react-dom';

// Extends dayjs with their timezone plugin
dayjs.extend(timeZone);

const CalendarContext = createContext();

// NOTE: doing manual `mapState/DispatchToProps` here, because model generator
// doesn't play nice withe the session models `get` selector (its a function
// that will have a new reference every time). So this is more of a temporary
// workaround!
const mapStateToProps = (state) => ({
  calendarSession: _.get(state, 'session.calendar'),
  calendarsSettings: _.get(state, 'session.global_settings.calendars_settings'),
  officeDetails: _.get(state, 'session.office_details'),
  userId: _.get(state, 'session.user_details.id')
});

const mapDispatchToProps = {
  setCalendarView: sessionModel.actionCreators.setCalendarView,
  setCalendarRange: sessionModel.actionCreators.setCalendarRange,
  setCalendarDate: sessionModel.actionCreators.setCalendarDate,
  loadingIndicatorOn: uiModel.actionCreators.loadingIndicatorOn,
  loadingIndicatorOff: uiModel.actionCreators.loadingIndicatorOff,
  setGlobalSettings: sessionModel.actionCreators.setGlobalSettings
};

const mapUpdatedEvent = (oldEvent, newEvent) => {
  if (oldEvent.uuid === newEvent.uuid) {
    return { ...oldEvent, ...newEvent };
  } else if (oldEvent.id === newEvent.id) {
    return {
      ...oldEvent,
      ..._.pick(newEvent, [
        'alerts',
        'allDay',
        'appointment_type',
        'appointment_type_id',
        'attachments',
        'attendees',
        'description',
        'end',
        'ends_at',
        'event_location',
        'starting_location',
        'event_status',
        'is_all_day',
        'is_recurring',
        'location_id',
        'private_note',
        'organiser',
        'records',
        'show_private_note',
        'start',
        'starts_at',
        'title',
        'travel_time',
        'travel_time_type'
      ])
    };
  }
  return oldEvent;
};

/**
 * This is where all the optimistic update magic happens. All the methods here are / should
 * be set up for this, optimistically updating the state (which we pass down via context)
 * and hitting the API in the background, resetting the state whenever we get an error
 * back. Having everything in this one place will hopefully help to make this somewhat
 * maintainable :/
 */
@connect(mapStateToProps, mapDispatchToProps)
@withErrorDialog
@withWhereabouts
@withModel(adminAppointmentTypesModel)
@withModel(userCalendarsModel)
@withModel(calendarsModel)
@withModel(apiCacheModel)
@autobind
class CalendarProvider extends PureComponent {
  constructor(props) {
    super(props);

    // Define moment locale for different "start of week" settings
    // See https://momentjs.com/docs/#/i18n/changing-locale/
    const startOfWeek =
      _.get(props, 'calendarsSettings.start_of_week.id') ||
      _.get(props, 'calendarsSettings.start_of_week');
    moment.defineLocale('en-custom', {
      parentLocale: 'en',
      week: {
        dow: startOfWeek === 'monday' ? 1 : startOfWeek === 'saturday' ? 6 : 0,
        doy: 1
      }
    });
    // For some reason moment sets the default locale to the newly defined one,
    // so we change it back to `en` here for everything else in the app +
    // pass `en-custom` through via the `culture` prop of react big calendar
    moment.locale('en');

    // We're loading a bunch of these settings etc from the session,
    // with reasonable fallbacks. This should be ok, we should be able to
    // assume the session has always been iniialised before any of this
    // runs!

    const sessionSplitWeek = _.get(
      props,
      'calendarsSettings.split_week_view',
      false
    );
    const sessionView = _.get(props, 'calendarSession.view', 'week');
    const currentView = this.getCurrentView(sessionView, sessionSplitWeek);

    let sessionDate = _.get(props, 'calendarSession.date')
      ? {
          ..._.get(props, 'calendarSession.date'),
          value: dayjs(_.get(props, 'calendarSession.date.value')).toDate()
        }
      : null;
    let sessionRange = _.get(props, 'calendarSession.range');

    if (!this.isValidDateOrRange(sessionDate)) {
      sessionDate = {
        value: dayjs().toDate()
      };
    }

    const startingUnit = ['month', 'day'].includes(sessionView)
      ? sessionView
      : 'week';

    if (!this.isValidDateOrRange(sessionRange)) {
      sessionRange = {
        start: moment().locale('en-custom').startOf(startingUnit).unix(),
        end: moment()
          .locale('en-custom')
          .endOf(startingUnit)
          .endOf('day')
          .unix()
      };
    }

    this.cancelSource = CancelToken.source();

    this.state = {
      // Loading state for initial data fetching
      eventsLoading: true,
      filtersLoading: true,
      userCalendarsLoading: true,
      appointmentTypesLoading: true,

      // Calendar settings / filters, mostly derived from the session
      // Note that we also store appointment types and user calendars
      // here, again mainly to be able to apply optimistic updates!
      workingHours: _.get(props, 'calendarsSettings.working_hours'),
      splitDay: _.get(props, 'calendarsSettings.split_day_view'),
      splitWeek: sessionSplitWeek,
      colorIndicator: _.get(
        props,
        'calendarsSettings.color_indicator',
        'calendars'
      ),
      userCalendars: [],
      adminAppointmentTypes: [],
      filteredUserCalendars: [],
      filteredTypes: [],
      includeNullType: true,
      currentView,
      currentDate: _.get(sessionDate, 'value', dayjs()),
      currentDateRange: sessionRange,

      // Events
      events: [],
      newEvent: null,
      monthsLoaded: [],

      // The following are the methods we want to expose through the context
      // For simplicity we keep those in the state!
      setEvents: this.setEvents,
      setSplitDay: this.setSplitDay,
      setSplitWeek: this.setSplitWeek,
      setCurrentView: this.setCurrentView,
      setFilteredUserCalendars: this.setFilteredUserCalendars,
      setFilteredTypes: this.setFilteredTypes,
      setCurrentDate: this.setCurrentDate,
      setCurrentDateRange: this.setCurrentDateRange,
      setColorIndicator: this.setColorIndicator,

      getUserCalendars: this.getUserCalendars,
      getAdminAppointmentTypes: this.getAdminAppointmentTypes,
      getEventColor: this.getEventColor,

      addEvent: this.addEvent,
      updateEvent: this.updateEvent,
      deleteEvent: this.deleteEvent,
      cancelEvent: this.cancelEvent,
      uncancelEvent: this.uncancelEvent,
      setNewEvent: this.setNewEvent,
      reloadEvent: this.reloadEvent,

      addUserCalendar: this.addUserCalendar,
      setUserCalendar: this.setUserCalendar,
      updateUserCalendar: this.updateUserCalendar,
      removeUserCalendar: this.removeUserCalendar,
      removeCalendar: this.removeCalendar,
      removeCalendarEvents: this.removeCalendarEvents,
      changeOrganiser: this.changeOrganiser,

      updateType: this.updateType,

      // We do some extra validation atm in the FE to make sure users are warned
      // if they haven't been provisioned a calendar yet / if anything went wrong
      // regarding that provisioning. The following is just used to keep track of
      // weather or not we showed the error dialog
      showError: false,
      errorDialogOpened: false,

      titleEventFilter: null,
      contactsFilter: [],
      propertiesFilter: [],
      listingsFilter: [],
      locationEventFilter: null,
      isFiltered: this.isFiltered,
      setFilters: this.setFilters,
      refresh: this.refresh
    };
  }

  componentDidMount() {
    const appointmentTypesCriteria = {
      criteria: [
        {
          name: 'is_hidden',
          type: '!=',
          value: true
        }
      ],
      limit: 50
    };

    Promise.all([
      this.getUserCalendars({ limit: 100 }),
      this.getAdminAppointmentTypes(appointmentTypesCriteria)
    ])
      .then(([userCalendars, adminAppointmentTypes]) => {
        this.setState({
          userCalendars: userCalendars || [],
          adminAppointmentTypes: adminAppointmentTypes || [],
          userCalendarsLoading: false,
          appointmentTypesLoading: false
        });
      })
      .catch(() => {
        this.setState({ showError: true });
      });
  }

  componentDidUpdate() {
    const {
      userCalendars,
      adminAppointmentTypes,
      userCalendarsLoading,
      appointmentTypesLoading,
      filtersLoading,
      errorDialogOpened,
      showError
    } = this.state;
    const { errorDialog, officeDetails, userId } = this.props;

    if (!userCalendarsLoading && !appointmentTypesLoading && filtersLoading) {
      // Calendars and types are loaded, now we should be able to fetch the rest
      // of the data ... or maybe before?!

      // NOTE: due to some back end irregularities, is_active could return either a boolean true/false, or an integer
      // 1/0, so we're explicitly using a loose truthy check here.
      const filteredCalendars = userCalendars.filter((c) => c.is_active);
      const filteredTypes = adminAppointmentTypes.filter((t) => t.is_active);
      this.setState(
        {
          filteredUserCalendars: filteredCalendars,
          filteredTypes: filteredTypes,
          filtersLoading: false
        },
        () => {
          // Initial event load based on the filters / settings set above
          // No real need to force here, since `monthsLoaded` is empty, but me
          this.loadEvents({ force: true });
        }
      );

      // Some FE side error handling regarding calendars not loading properly,
      // since it could be related to dodgy provisioning
      if (!errorDialogOpened) {
        // Loading the calendars failed for whatever reason. We want to show a generic
        // error message in this case, showing the account ID so it's easier to communicate
        // if they contact support
        // userId 102 is the support user - this check is simply here to stop support from
        // needing to close the error dialog every time they enter calendar
        if (!userCalendars.length && Number(userId) !== 102) {
          // Same thing if there are no errors, but no calendars were found. ALL users are
          // supposed to have a default calendar they cannot delete, so if the general query
          // can't find any, it's a problem with the provisioning!
          errorDialog.open(
            new Error(
              'We couldn’t find any calendars for your account. Please contact support so ' +
                'we can provision you to get you started. Your Account ID is ' +
                `#${_.get(officeDetails, 'id')} ` +
                `(${_.get(officeDetails, 'name')})`
            )
          );
          this.setState(() => ({ errorDialogOpened: true }));
        } else if (showError) {
          errorDialog.open(
            new Error(
              'Something went wrong while loading your calendars or appointment types. ' +
                'Please try refreshing the page or contact support. Your Account ID is ' +
                `#${_.get(officeDetails, 'id')} ` +
                `(${_.get(officeDetails, 'name')})`
            )
          );
          this.setState(() => ({ errorDialogOpened: true }));
        }
      }
    }
  }

  getCurrentView(
    view = this.state.currentView,
    splitWeek = this.state.splitWeek
  ) {
    const { calendarsSettings } = this.props;

    if (view === 'week' && !_.get(calendarsSettings, 'show_weekends')) {
      if (splitWeek) {
        return 'split_work_week';
      } else {
        return 'work_week';
      }
    }

    if (view === 'week' && splitWeek) {
      return 'split_week';
    }

    return view;
  }

  loaderPromises = [];

  hideLoader(promise, error) {
    const { loadingIndicatorOff, errorDialog } = this.props;

    if (error) {
      if (error.problem === 'RecordNotFoundException') {
        error.message =
          'The calendar you selected is no longer available. Please reload or select another calendar.';
      }
      loadingIndicatorOff();
      this.loaderPromises = [];
      errorDialog.open(error);
      this.refresh();
      return;
    }

    this.loaderPromises = this.loaderPromises.filter((p) => p !== promise);

    if (this.loaderPromises.length === 0) {
      loadingIndicatorOff();
    }
  }

  showLoader(message, promise) {
    const { loadingIndicatorOn } = this.props;
    promise
      .then(() => this.hideLoader(promise))
      .catch((error) => this.hideLoader(promise, error));
    this.loaderPromises.push(promise);
    loadingIndicatorOn({ message });
    return promise;
  }

  isValidDateOrRange(dateOrRange) {
    return (
      dateOrRange &&
      dateOrRange.changed &&
      (dayjs().startOf('day').isBefore(dayjs(dateOrRange.changed)) ||
        dayjs().subtract(1, 'hour').isBefore(dayjs(dateOrRange.changed)))
    );
  }

  setFilters({ title, contacts, properties, listings, location }) {
    this.setState(
      {
        titleEventFilter: title,
        contactsFilter: contacts,
        propertiesFilter: properties,
        listingsFilter: listings,
        locationEventFilter: location
      },
      this.refresh
    );
  }

  isFiltered() {
    const {
      titleEventFilter,
      contactsFilter,
      propertiesFilter,
      listingsFilter,
      locationEventFilter
    } = this.state;

    return (
      titleEventFilter ||
      contactsFilter.length ||
      propertiesFilter.length ||
      listingsFilter.length ||
      locationEventFilter
    );
  }

  async loadAll(args, cancelSource) {
    // If the requests have already been cancelled, don't do any subsequent requests here
    if (cancelSource !== this.cancelSource) {
      return;
    }

    // HACK: hard swapping from and to unix times to match saturday/sunday(default)/monday start of week user setting
    if (_.isArray(_.get(args, 'criteria'))) {
      const fromCriterion = args.criteria.find((criterion) => {
        return criterion.name === 'from';
      });
      const toCriterion = args.criteria.find((criterion) => {
        return criterion.name === 'to';
      });

      const fromValue = fromCriterion.value;
      const toValue = toCriterion.value;

      if (fromValue && toValue) {
        const criteria = args.criteria
          .filter((c) => {
            return !(c.name === 'from' || c.name === 'to');
          })
          .concat([
            { name: 'from', value: fromValue },
            { name: 'to', value: toValue }
          ]);

        args = { ...args, criteria };
      }
    }

    const calendarEvents = await api.fetchAll(
      'CalendarEvents::search',
      { ...args, limit: 500 },
      { cancelToken: this.cancelSource.token }
    );

    const calendarIds = this.state.filteredUserCalendars
      .map((c) => _.get(c, 'calendar.id', ''))
      .filter(Boolean);

    // Concerning includeNullType - If a second request comes back
    // after the 'Other' checkbox has been toggled off again
    // we want it to respond to the changed state of includeNullType
    // and filter out events that don't have an appointment type.
    return calendarEvents.rows.filter((event) => {
      if (!calendarIds.includes(_.get(event, 'calendar.id'))) {
        return false;
      }

      if (
        !this.state.includeNullType &&
        _.get(event, 'appointment_type') === null
      ) {
        return false;
      }

      // If the event is declined on a connected calendar we don't display in Rex
      // This is because the user can't change this status through Rex so the
      // info is unlikely to be useful
      return !(_.get(event, 'participation_status.id') === 'declined');
    });
  }

  async getUserCalendars(args) {
    const { errorDialog } = this.props;

    try {
      const userCalendars = await api.fetchAll('UserCalendars::search', args);
      return userCalendars.rows;
    } catch (e) {
      errorDialog.open(e);
    }
  }

  async getAdminAppointmentTypes(args) {
    const { errorDialog } = this.props;

    try {
      const adminAppointmentTypes = await api.fetchAll(
        'AdminAppointmentTypes::search',
        args
      );
      return adminAppointmentTypes.rows;
    } catch (e) {
      errorDialog.open(e);
    }
  }

  registeredLoadEventsOptions = null;
  loadEventsTimer = null;

  throttledDebounceLoadEvents() {
    // Reset timer
    this.loadEventsTimer = null;
    if (this.registeredLoadEventsOptions) {
      // There are registered requests, we currently store the options of
      // the last one, so use that to trigger another API fetch and start the
      // whole cycle all over again
      const options = this.registeredLoadEventsOptions;
      this.registeredLoadEventsOptions = null;
      this.loadEvents(options);
    }
  }

  makeFilterCriteria() {
    const {
      titleEventFilter,
      contactsFilter,
      propertiesFilter,
      listingsFilter,
      locationEventFilter
    } = this.state;

    return [
      // Why we search location by label?
      // label - <location address>
      // value - <location address> + <country name>
      // When we add a location to an event, FE uses label. Why? because it's the same value in the input on edit that we put in on save (copied this reason from /utils/calendar.js)
      // This is to ensures that the value displays the same way in both cases, avoiding potential confusion for the user.
      _.get(locationEventFilter, 'label') && {
        name: 'event_location',
        type: '=',
        value: _.get(locationEventFilter, 'label')
      },
      titleEventFilter && {
        name: 'title',
        type: 'like',
        value: `%${titleEventFilter}%`
      },
      _.get(contactsFilter, 'length') && {
        name: 'records.contact_id',
        type: 'in',
        value: _.map(contactsFilter, 'value')
      },
      _.get(propertiesFilter, 'length') && {
        name: 'records.property_id',
        type: 'in',
        value: _.map(propertiesFilter, 'value')
      },
      _.get(listingsFilter, 'length') && {
        name: 'records.listing_id',
        type: 'in',
        value: _.map(listingsFilter, 'value')
      }
    ].filter(Boolean);
  }

  async loadEvents(options) {
    // The options are also used to indicate whether there is a pending load
    // that has been delayed by the timeout, so make sure we always have
    // options.
    options = options || { force: false };
    if (this.loadEventsTimer) {
      // Time is active, so just register and bail.
      // We also want to reset the timer for proper throttle/debounce?!
      clearTimeout(this.loadEventsTimer);
      this.loadEventsTimer = setTimeout(this.throttledDebounceLoadEvents, 500);

      // If a forced load was required, it's safe to assume that it still is.
      this.registeredLoadEventsOptions =
        this.registeredLoadEventsOptions &&
        this.registeredLoadEventsOptions.force
          ? { ...options, force: true }
          : options;
      return;
    }

    // Cancel all pending requests
    if (this.cancelSource) {
      this.cancelSource.cancel();
      this.cancelSource = CancelToken.source();
    }

    // Start timer that will check for registered requests that occur in the meanwhile
    // and handles potentially necessary further fetches
    this.loadEventsTimer = setTimeout(this.throttledDebounceLoadEvents, 500);

    // NOTE: we're not using model generator here to have easier controll over what we
    // load when and how we deal with the optimistic stuff!
    const {
      currentView,
      currentDateRange,
      filteredUserCalendars,
      filteredTypes,
      userCalendars,
      adminAppointmentTypes,
      includeNullType
    } = this.state;

    const {
      loadingIndicatorOn,
      loadingIndicatorOff,
      errorDialog,
      whereabouts
    } = this.props;

    // When a calendar is being connected, manipulation of the loading
    // indicator should be left to the connect dialog that will be shown atop
    // the calendar.
    const isConnecting = /\/calendar\/connect/.test(whereabouts.path);
    const delegatedLoadingIndicatorOn = isConnecting
      ? () => undefined
      : loadingIndicatorOn;
    const delegatedLoadingIndicatorOff = isConnecting
      ? () => undefined
      : loadingIndicatorOff;

    const filterCriteria = this.makeFilterCriteria();

    try {
      delegatedLoadingIndicatorOn({ message: 'Loading appointments' });
      // We reverse the criteria to `notin` when we want to show events with no
      // appointment type. (this is decided by `includeNullType` which is controlled
      // by the appointment type checkbox labled 'Other').
      // The following criteria will NOT get events that have 'null' as the
      // appointment type:
      // {
      //   name: 'appointment_type_id',
      //   type: 'in',
      //   value: [all appointment types except 1 and 2]    // includedTypeIds'
      // }
      // (This is what we do when `includeNullType` is false)
      //
      // So to filter by appointment types, but still get events without an
      // appointment type, we need the criteria to specify the event types that
      // we DON'T want, while getting everything else - so we use
      // `notin` + a list of all the appointment types we don't want.
      //
      // The same criteria as above but with `includeNullType === true`:
      // {
      //   name: 'appointment_type_id',
      //   type: 'notin',
      //   value: [1, 2]                                 // 'excludedTypeIds'
      // }
      // Note that you can't filter out null with a notin criteria.
      // Hence the need for both in and notin.

      // includedTypeIds is all the appointment types we want to include -
      // for an 'in' criteria.
      const includedTypeIds = filteredTypes.map((t) => t.id).filter(Boolean);
      // excludedTypeIds is all the appointment types we want to filter out -
      // for a 'notin' criteria.
      const excludedTypeIds = _.difference(
        adminAppointmentTypes.map((t) => t.id),
        includedTypeIds
      ).filter(Boolean);

      const calendarIds = filteredUserCalendars
        .map((c) => _.get(c, 'calendar.id'))
        .filter(Boolean);

      // Start with a fresh array if we want to force reload, preventing duplicates
      let newEvents = _.get(options, 'force') ? [] : this.state.events;

      const rangeStart = dayjs(currentDateRange.start * 1000).unix();
      const rangeEnd = dayjs(currentDateRange.end * 1000).unix();

      const rangeEvents = await this.loadAll(
        {
          criteria: [
            ...filterCriteria,
            {
              name: 'calendar_id',
              type: 'in',
              value: calendarIds
            },
            {
              // The comment above includedTypeIds (initialised with const) explains this code.
              name: 'appointment_type_id',
              type: includeNullType ? 'notin' : 'in',
              value: includeNullType ? excludedTypeIds : includedTypeIds
            },
            {
              name: 'from',
              value: rangeStart
            },
            {
              name: 'to',
              value: rangeEnd
            }
          ]
        },
        this.cancelSource
      );

      if (!rangeEvents) {
        return;
      }

      const mappedEvents = _.flatMap(rangeEvents, (apiData) =>
        mapApiToEvents(apiData, {
          userCalendars,
          adminAppointmentTypes,
          currentView
        })
      );

      this.setState(() => {
        // Ensure we keep previous state here when navigating multiple pages in a short time span
        newEvents = _.uniqBy(
          [...newEvents, ...mappedEvents],
          (event) => event.uuid
        );

        return {
          events: newEvents,
          eventsLoading: false
        };
      });

      delegatedLoadingIndicatorOff();
    } catch (e) {
      if (e.problem === 'CANCEL_ERROR') {
        // Don't do anything on request cancellation
        // NOTE: we cannot use `axios.isCancel` here, since we f*** around with
        // the thrown errors a bit in our api client, so the error we're getting
        // here is actually NOT what axios throws on cancellation of the request!
        return;
      }
      errorDialog.open(e);

      delegatedLoadingIndicatorOff();
    }
  }

  setSplitDay(val) {
    const { setGlobalSettings, calendarsSettings } = this.props;

    // Optimistic update
    this.setState({ splitDay: val });

    // API action to update settings accordingly
    return this.showLoader(
      'Saving',
      setGlobalSettings({
        settings: {
          calendars_settings: {
            ...calendarsSettings,
            split_day_view: val
          }
        }
      })
    );
  }

  setSplitWeek(val) {
    const { setGlobalSettings, calendarsSettings } = this.props;

    // Optimistic update
    this.setState({ splitWeek: val });

    // API action to update settings accordingly
    return this.showLoader(
      'Saving',
      setGlobalSettings({
        settings: {
          calendars_settings: {
            ...calendarsSettings,
            split_week_view: val
          }
        }
      })
    );
  }

  setColorIndicator(colorIndicator) {
    const { calendarsSettings, setGlobalSettings } = this.props;

    // Optimistic update
    this.setState({ colorIndicator });

    // API action to update settings accordingly
    return this.showLoader(
      'Saving',
      setGlobalSettings({
        settings: {
          calendars_settings: {
            ...calendarsSettings,
            color_indicator: colorIndicator
          }
        }
      })
    );
  }

  setCurrentView(view, splitWeek) {
    const currentView = this.getCurrentView(view, splitWeek);

    // Optmisitic update
    this.setState({ currentView });

    // Store view in local storage against the session, so the
    // user remains on the same view when e.g. refreshing the page
    // Set as the base view and not the derived view as we always
    // want to derive the view on load
    this.props.setCalendarView(view);
  }

  setFilteredUserCalendars(calendars) {
    const { userCalendars } = this.props;

    const updates = [];
    let needsRefresh = false;

    calendars.forEach((calendar) => {
      if (!this.state.filteredUserCalendars.find((c) => c.id === calendar.id)) {
        updates.push({
          id: calendar.id,
          data: { id: calendar.id, is_active: true }
        });
        needsRefresh = true;
      }
    });

    this.state.filteredUserCalendars.forEach((calendar) => {
      if (!calendars.find((c) => c.id === calendar.id)) {
        updates.push({
          id: calendar.id,
          data: { id: calendar.id, is_active: false }
        });
      }
    });

    // Optimistic update
    this.setState(
      {
        filteredUserCalendars: calendars,
        // FE filter, so we don't need to re-fetch when removing a calendar from
        // the filters + the events disappear instantly
        events: this.state.events.filter((event) =>
          calendars
            .map((c) => _.get(c, 'calendar.id'))
            .includes(_.get(event, 'calendar.id'))
        )
      },
      () => {
        // re-fetch events here if a type was added
        if (needsRefresh) {
          this.refresh(true);
        }
      }
    );

    // API call to update `is_active`
    return this.showLoader(
      'Saving filters',
      Promise.all(
        updates.map((updateData) => userCalendars.updateItem(updateData))
      )
    );
  }

  /**
   * Filters events by appointment type on FE (optimistically), saves the filters by
   * sending an update to adminAppointmentTypes for each changed filter and begins the
   * process of requesting filtered events from BE (see this.loadEvents) if new events
   * are required.
   * @param {array} types - array of appointment types, each an object of the structure
   * recieved from adminAppointmentTypes. This will be set as the new this.state.filteredTypes value.
   * @param {boolean} includeNullType - value which indicates whether or not events which
   * have an appointment type of null should be included in shown events.
   */
  setFilteredTypes(types, includeNullType) {
    const { adminAppointmentTypes } = this.props;
    const { includeNullType: oldIncludeNull } = this.state;
    const updates = [];
    let needsRefresh = includeNullType !== oldIncludeNull;

    types.forEach((type) => {
      if (!this.state.filteredTypes.find((t) => t.id === type.id)) {
        updates.push({ id: type.id, data: { id: type.id, is_active: true } });
        needsRefresh = true;
      }
    });

    this.state.filteredTypes.forEach((type) => {
      if (!types.find((t) => t.id === type.id)) {
        updates.push({ id: type.id, data: { id: type.id, is_active: false } });
      }
    });
    // Optimistic update
    this.setState(
      {
        filteredTypes: types,
        includeNullType,

        // FE filter, so we don't need to re-fetch when removing a type from
        // the filters + the events disappear instantly
        events: this.state.events.filter((event) => {
          const typeId = _.get(event, 'appointment_type.id');
          if (!typeId) {
            return includeNullType;
          }
          return types.map((t) => t.id).includes(typeId);
        })
      },
      () => {
        // re-fetch events here if a type was added
        if (needsRefresh) {
          this.refresh(true);
        }
      }
    );

    if (updates.length) {
      this.showLoader(
        'Saving filters',
        Promise.all(
          updates.map((updateData) =>
            adminAppointmentTypes.updateItem(updateData)
          )
        )
      );
    }
  }

  setCurrentDateRange({ start, end }) {
    // Make sure that the timestamp we're giving to the API
    // begins at midnight on the start of the range of days
    // and ends at 23:59 on the end
    const range = {
      start: moment.unix(start).locale('en-custom').startOf('day').unix(),
      end: moment.unix(end).locale('en-custom').endOf('day').unix()
    };

    // Optimistic update
    this.setState({ currentDateRange: range }, () => {
      this.loadEvents();
    });

    // Updating the current date range
    // NOTE: check what this does and maybe move API logic
    // in here?!
    this.props.setCalendarRange(range);
  }

  setCurrentDate(date) {
    // Optimistic update
    this.setState({ currentDate: date });

    // Setting the date in local storage against the session,
    // so when the user refreshed the page they remain on the
    // same screen :)
    this.props.setCalendarDate(date);
  }

  setEvents(events) {
    // Initial set for the events state from given array
    this.setState({ events });
  }

  addEvent(event) {
    const { userCalendars } = this.state;

    // Optimistic update
    let newEvents = this.state.events;

    // Extract the temporary ID from within the uuid constructed in mapApiToEvent
    // The actual event will have the same temporary ID as any associated travel-time event
    const tmpId = getTmpEventId(event.uuid);

    const userCalendarIsVisible = userCalendars.find(
      (c) => _.get(c, 'calendar.id') === _.get(event, 'calendar.id')
    );

    const clientTimezone = getClientTimezone();

    // If no timezone was set on the event by the user we default to the
    // current client timezone
    const startTz = event.starts_at.tzid || clientTimezone;
    const endTz = event.ends_at.tzid || clientTimezone;

    // Formats the timestamp correctly for the backend, with the correct timezone offset
    const startsAtTime = dayjs(event.starts_at.time).format(
      'YYYY-MM-DDTHH:mm:ssZ',
      { timeZone: startTz }
    );
    const endsAtTime = dayjs(event.ends_at.time).format(
      'YYYY-MM-DDTHH:mm:ssZ',
      { timeZone: endTz }
    );

    const startsAt = {
      time: startsAtTime,
      tzid: startTz
    };
    const endsAt = {
      time: endsAtTime,
      tzid: endTz
    };

    event = {
      ...event,
      starts_at: startsAt,
      ends_at: endsAt
    };

    if (userCalendarIsVisible) {
      newEvents = [
        ...newEvents,
        {
          ...event,
          id: tmpId,
          isCreating: true,
          security_user_rights: ['update']
        }
      ];
    }

    if (newEvents.find((e) => e.id === 'new')) {
      newEvents = newEvents.filter((e) => e.id !== 'new');
    }

    this.setState({
      events: newEvents
    });

    // API call to create event
    // + set the actual ID once we got the response
    return this.showLoader(
      'Saving',
      api
        .post('CalendarEvents::create', { data: mapEventToApi(event) })
        .then(({ data }) => {
          // flushSync needed as part of React 18 upgrade to fix bug
          // whereby state update wasn't getting applied correctly, leading to the created
          // event to get stuck
          flushSync(() => {
            this.setState((state) => ({
              events: state.events.map((e) => {
                if (e.id === tmpId) {
                  return {
                    ...e,
                    uuid: e.uuid.replace(tmpId, _.get(data, 'result.id')),
                    id: _.get(data, 'result.id'),
                    isCreating: false,

                    // Drag-to-create events start as "opaque"
                    // by default, so lets ensure transparency is updated
                    transparency: _.get(data, 'result.transparency'),
                    is_recurring: _.get(data, 'result.is_recurring'),

                    // Remote update / delete permissions are set on the backend on create
                    // These return true if the calendar isn't connected so still relevant
                    remote_permission_can_update: _.get(
                      data,
                      'result.remote_permission_can_update'
                    ),
                    remote_permission_can_delete: _.get(
                      data,
                      'result.remote_permission_can_delete'
                    )
                  };
                }

                return e;
              })
            }));
          });

          return data;
        })
        .then((data) => {
          if (_.get(data, 'result.is_recurring')) {
            this.refreshRecurringEvents();
          }

          return data;
        })
        .then((data) => {
          Analytics.track({
            event: EVENTS.CALENDAR.CREATE_APPOINTMENT,
            options: { integrations: { Intercom: true } }
          });
          if (event.followup_reminder) {
            Analytics.track({
              event: EVENTS.CALENDAR.CREATE_FOLLOWUP_REMINDER
            });
          }
          return data;
        })
    );
  }

  updateEvent(event) {
    const { userCalendars, filteredUserCalendars } = this.state;

    // Some actions can't be done optimistically, so we check for those and
    // set `needsRefresh` accordingly
    let needsRefresh = false;
    const oldEvent = this.state.events.find((e) => e.uuid === event.uuid);

    const oldGuests = _.get(oldEvent, 'attendees', [])
      .map((a) => `${a.type}-${a.id}`)
      .join('|');
    const newGuests = _.get(event, 'attendees', [])
      .map((a) => `${a.type}-${a.id}`)
      .join('|');
    if (oldGuests !== newGuests) {
      // Guests changed, so we want to refresh
      // We could try to do this optimistically as well, but this would become
      // a mess once we deal with permissions I guess
      needsRefresh = true;
    }

    // Need to refresh to update all instances of a recurring event.
    if (
      _.get(event, 'is_recurring') ||
      _.get(oldEvent, 'is_recurring') !== _.get(event, 'is_recurring')
    ) {
      needsRefresh = true;
    }

    const eventCalendar = userCalendars.find(
      (c) => _.get(c, 'calendar.id') === _.get(event, 'calendar.id')
    );

    // As per UX, we want to activate a calendar if its in the user calendars
    // but currently disabled in the filters ... weird, but thats how they
    // want it ;)
    const shouldAddFilteredCalendar =
      !!eventCalendar &&
      !filteredUserCalendars.find(
        (c) => _.get(c, 'calendar.id') === _.get(event, 'calendar.id')
      );

    const newEvents = oldEvent
      ? this.state.events
          .map((e) => {
            const newEvent = mapUpdatedEvent(e, event);
            if (newEvent !== e) {
              // Don't keep event in our event list if the user doesn't have the calendar it's attached to
              if (
                !userCalendars.find(
                  (c) => _.get(c, 'calendar.id') === _.get(event, 'calendar.id')
                )
              ) {
                return;
              }
              // TODO: filter by permissions once available
              return newEvent;
            }
            return e;
          })
          .filter(Boolean)
      : [...this.state.events, event];

    // Optimistic update
    this.setState({
      events: newEvents
    });

    if (shouldAddFilteredCalendar) {
      this.setFilteredUserCalendars([...filteredUserCalendars, eventCalendar]);
    }

    // Actual API call to update event in the background
    return this.showLoader(
      'Saving',
      api
        .post('CalendarEvents::update', { data: mapEventToApi(event) })
        .then((response) => {
          if (event.has_changed_organiser) {
            needsRefresh = true;
            return this.changeOrganiser(event);
          }

          return response;
        })
        .then((res) => {
          if (_.get(event, 'update_recurring_events') && needsRefresh) {
            this.refreshRecurringEvents();

            return res;
          }

          // Refresh if any fields changed that makes this necessary, e.g.
          // changing guests (so we need to make sure we get all the new event
          // instances), changing the owner, etc
          if (needsRefresh) {
            this.refresh();
          }

          return res;
        })
    );
  }

  changeOrganiser(event) {
    return api.post('CalendarEvents::changeOrganiser', {
      data: {
        event_id: event.id,
        new_organiser_calendar: { id: event.new_calendar_id },
        update_recurring_events: !!event.is_recurring
      }
    });
  }

  deleteEvent(eventId, needsRefresh) {
    // Optimistic update
    this.setState({
      events: this.state.events.filter((e) => e.id !== eventId)
    });

    // No need for API call here, since the delete dialog will always
    // take care of that!
    // Except on recurring event, which requires a list refresh
    if (needsRefresh) {
      this.refresh();
    }
  }

  cancelEvent(eventId, needsRefresh) {
    const newEvents = this.state.events.map((e) => {
      if (e.id === eventId) {
        return {
          ...e,
          event_status: {
            id: 'cancelled',
            text: 'Cancelled'
          }
        };
      }
      return e;
    });

    // Optimistic update
    this.setState({
      events: newEvents
    });

    // No need for API call here, since the cancel dialog will always
    // take care of that!
    // Except on recurring event, which requires a list refresh
    if (needsRefresh) {
      this.refresh();
    }
  }

  uncancelEvent(eventId, needsRefresh) {
    const newEvents = this.state.events.map((e) => {
      if (e.id === eventId) {
        return {
          ...e,
          event_status: {
            id: 'confirmed',
            text: 'Confirmed'
          }
        };
      }
      return e;
    });

    // Optimistic update
    this.setState({
      events: newEvents
    });

    // No need for API call here, since the dialog will always
    // take care of that!
    // Except on recurring event, which requires a list refresh
    if (needsRefresh) {
      this.refresh();
    }
  }

  setNewEvent(event) {
    // Just state update needed, no API actions required
    if (!event) {
      // Remove new event
      this.setState({
        events: this.state.events.filter((e) => e.id !== 'new')
      });
      return;
    }

    // const newEvent = { ...event };
    // newEvent.userCalendar.color = this.getEventColor(event);

    // Update new event with given data
    // Merge for simplified usage
    this.setState({
      events: this.state.events.find((e) => e.id === 'new')
        ? this.state.events.map((e) =>
            e.id === 'new' ? { ...e, ...event } : e
          )
        : [...this.state.events, { ...event, id: 'new' }]
    });
  }

  reloadEvent(event) {
    // This method does not touch the API. It's for events that are reloaded
    // using a mechanism that's external to this context. For example, the
    // collision-avoidance bar in the edit event dialog has a reload button
    // and it that dialog reads from the API directly.

    const newEvents = this.state.events
      .map((e) => mapUpdatedEvent(e, event))
      .filter(Boolean);

    // Optimistic update
    this.setState({
      events: newEvents
    });
  }

  refetchUserCalendars() {
    const { cache } = this.props;
    cache.fetch({
      method: 'UserCalendars::search',
      args: {
        limit: 100,
        offset: 0
      },
      force: true
    });
  }

  setUserCalendar(calendar) {
    // Optimistic update
    // NOTE: this is mainly for updates of the associated color
    // ALSO NOTE: if the new calendar is set as default, remove
    // the default from the current default
    this.setState({
      userCalendars: this.state.userCalendars.map((c) => {
        if (c.id === calendar.id) {
          return {
            ...c,
            ...calendar
          };
        }
        return {
          ...c,
          is_default: calendar.is_default ? false : c.is_default
        };
      })
    });
  }

  updateUserCalendar(calendar) {
    const { userCalendars } = this.props;
    this.setUserCalendar(calendar);

    // Actual API call to update user calendar
    return this.showLoader(
      'Saving calendar',
      userCalendars
        .updateItem({ id: calendar.id, data: calendar })
        .then(() => this.refetchUserCalendars())
    );
  }

  addUserCalendar(calendar) {
    this.setState(
      (state) => ({
        userCalendars: [...state.userCalendars, calendar],
        filteredUserCalendars: [...state.filteredUserCalendars, calendar]
      }),
      () => {
        this.refresh(true);
        this.refetchUserCalendars();
      }
    );
  }

  removeUserCalendar(userCalendarId) {
    const { userCalendars } = this.props;
    const { filteredUserCalendars, events } = this.state;

    // Optimistic update
    this.setState({
      userCalendars: this.state.userCalendars.filter(
        (calendar) => calendar.id !== userCalendarId
      ),
      filteredUserCalendars: filteredUserCalendars.filter(
        (calendar) => calendar.id !== userCalendarId
      ),
      events: events.filter(
        (event) => _.get(event, 'userCalendar.id') !== userCalendarId
      )
    });

    // Actual API call to remove user calendar
    // Or calendar if the current user is the owner!
    return this.showLoader(
      'Removing calendar',
      userCalendars
        .trashItem({ id: userCalendarId })
        .then(() => this.refetchUserCalendars())
    );
  }

  removeCalendar(calendarId, { formValues }) {
    const { filteredUserCalendars, events } = this.state;

    const userCalendar = this.state.userCalendars.find(
      (c) => _.get(c, 'calendar.id') === calendarId
    );
    const userCalendarId = _.get(userCalendar, 'id');

    // Optimistic update
    this.setState(
      {
        userCalendars: this.state.userCalendars.filter(
          (calendar) => calendar.id !== userCalendarId
        ),
        filteredUserCalendars: filteredUserCalendars.filter(
          (calendar) => calendar.id !== userCalendarId
        ),
        events: events.filter(
          (event) => _.get(event, 'userCalendar.id') !== userCalendarId
        )
      },
      () => {
        // We want to refresh here in case the events have been moved to
        // another calendar in the process!
        if (_.get(formValues, 'mode') === 'move') {
          this.refresh({ force: true });
        }

        this.refetchUserCalendars();
      }
    );

    // No API update needed, the `Delete Calendar` dialog will always
    // take care of that one!
  }

  removeCalendarEvents(calendarId) {
    const { events } = this.state;
    const newEvents = events.filter(
      (event) => _.get(event, 'calendar.id') !== calendarId
    );

    // Optimistic update
    this.setState({ events: newEvents });

    // No API update needed. This is only performed in response to a calendar
    // being disconnected.
  }

  updateType(type) {
    const { adminAppointmentTypes } = this.props;

    // Optimistic update
    this.setState({
      adminAppointmentTypes: this.state.adminAppointmentTypes.map((t) => {
        if (t.id === type.id) {
          return { ...t, ...type };
        }
        return t;
      })
    });

    // TODO: actual API call to update appointment type
    return this.showLoader(
      'Saving type',
      adminAppointmentTypes.updateItem({ id: type.id, data: type })
    );
  }

  refresh() {
    this.setState({ eventsLoading: true });
    // Nothing optimistic here, this should ONLY be called when something went
    // wrong and we want to reset the optimisitc local state to the current
    // server state
    this.loadEvents({ force: true });

    // TODO: Load filters, types and calendars from model generator
  }

  refreshRecurringEvents() {
    // Recurring events are updated by deleting the copies and
    // recreating them on the backend asynchronously
    // Depending on the number of event copies needed
    // (as defined by the recurrence rule and the number of guests),
    // this could take some time.
    // We delay the refresh in this case because if we don't, we're
    // almost guaranteed to not get the event copies back

    // To try to grab the copies as soon as possible, while also minimise the
    // chance that we will miss any, we set up three separate delayed requests
    // here at 2, 5 and 10 seconds. Best case scenario only 2 seconds will elapse before they appear,
    // but 10 seconds is a decent amount of time to allow for complex recurrence rules to process
    setTimeout(this.refresh, 2000);
    setTimeout(this.refresh, 5000);
    setTimeout(this.refresh, 10000);
  }

  getEventColor(event) {
    const { adminAppointmentTypes, userCalendars, colorIndicator } = this.state;

    let color;

    if (colorIndicator === 'calendar') {
      const calendar = userCalendars.find(
        (c) => _.get(c, 'calendar.id') === _.get(event, 'calendar.id')
      );
      color = _.get(calendar, 'color');
    } else {
      const type = adminAppointmentTypes.find(
        (t) => t.id === _.get(event, 'appointment_type.id')
      );
      color = _.get(type, 'color');
    }

    color = color || '#929088';

    return color;
  }

  sortEvents = _.memoize((events) => {
    const { filteredUserCalendars } = this.state;
    const calendarIds = filteredUserCalendars.map((c) => c.calendar.id);

    const sortedEvents = [...events].sort((a, b) => {
      const aIndex = calendarIds.indexOf(_.get(a, 'calendar.id'));
      const bIndex = calendarIds.indexOf(_.get(b, 'calendar.id'));

      if (aIndex < bIndex) {
        return -1;
      } else if (aIndex > bIndex) {
        return 1;
      }

      return 0;
    });

    return sortedEvents;
  });

  getValue = _.memoize((state) => ({
    ...state,
    events: this.sortEvents(state.events)
  }));

  render() {
    return (
      <CalendarContext.Provider
        value={this.getValue(this.state)}
        {...this.props}
      />
    );
  }
}

/**
 * @param {string[]} dependencies parts of the context state that should affect shouldComponentUpdate
 *
 * @returns {(WrappedComponent: React.Component) => React.Component}
 */
const withCalendarContext =
  (dependencies = []) =>
  (WrappedComponent) => {
    class ContextPerformanceBooster extends React.Component {
      shouldComponentUpdate(nextProps) {
        const hasContextDependencyChanged = _.some(
          dependencies,
          (contextKey) =>
            nextProps.context[contextKey] !== this.props.context[contextKey]
        );
        const changedProps = _.filter(
          Object.keys(_.omit(nextProps, 'context')),
          (propKey) => !_.isEqual(nextProps[propKey], this.props[propKey])
        );

        if (hasContextDependencyChanged) {
          return true;
        }

        // Because our calendar has disabled drag & drop, we don't
        // need to rerender based on 'draggable' prop changes.
        // This change saves a LOT of unnecessary rerenders when e.g.
        // opening a view-only appointment popout.
        if (changedProps.length === 1 && changedProps[0] === 'draggable') {
          return false;
        }

        return changedProps.length > 0;
      }

      render() {
        return <WrappedComponent {...this.props} />;
      }
    }

    return class ContextWrapper extends PureComponent {
      render() {
        return (
          <CalendarContext.Consumer>
            {(context) => (
              <ContextPerformanceBooster
                {...this.props}
                context={{ ..._.get(this, 'props.context', {}), ...context }}
              />
            )}
          </CalendarContext.Consumer>
        );
      }
    };
  };

const CalendarConsumer = CalendarContext.Consumer;

export {
  CalendarContext,
  CalendarProvider,
  CalendarConsumer,
  withCalendarContext
};
