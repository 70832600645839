import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { HorizontalDivider } from 'components/horizontal-divider';
import { Body } from 'components/text/body';
import { DialogBody } from 'components/text/dialog-body';
import { useDialog } from 'hooks/use-dialog';
import React from 'react';
import Icon, { ICONS } from 'shared/components/icon';
import { COLORS } from 'src/theme';
import DefaultButton from 'src/view/components/button/default-button';
import { useRegion } from 'hooks/use-region';
import { formatCurrency } from 'src/utils/formatters';
import dayjs from 'dayjs';
import { useModelActions } from '@rexlabs/model-generator';
import uiModel from 'data/models/custom/ui';
import {
  GetSyncTotalsResponse,
  WingsXero
} from '../../types/third-party-service-xero';
import { useErrorDialog } from 'hooks/use-error-dialog';
import { SyncInvoiceResponse } from '../../types/invoice';
import { SyncPaymentResponse } from '../../types/payment';

const styles = StyleSheet({
  title: {
    fontSize: '27px',
    color: COLORS.DARK_GREY,
    fontWeight: 600
  },
  dot: {
    fontSize: 30
  }
});

interface SyncInvoiceHeaderProps {
  getPayments: (refresh?: boolean) => Promise<unknown>;
  syncPayments: () => Promise<
    SyncPaymentResponse & { failed: (string | number)[] }
  >;
  selectedPayments: (string | number)[];
  ignoredPayments: string[];
  getInvoices: (refresh?: boolean) => Promise<unknown>;
  syncInvoices: () => Promise<SyncInvoiceResponse | null>;
  selectedInvoices: (string | number)[];
  ignoredInvoices: string[];
  hasInvoicesOrPayments: boolean;
  lastSynced?: string;
  wingsXero: WingsXero;
  isLoading: boolean;
}

const SyncInvoicesHeader = ({
  getPayments,
  syncPayments,
  selectedPayments,
  getInvoices,
  syncInvoices,
  selectedInvoices,
  ignoredInvoices,
  ignoredPayments,
  hasInvoicesOrPayments,
  lastSynced,
  wingsXero,
  isLoading
}: SyncInvoiceHeaderProps) => {
  const errorDialog = useErrorDialog();
  const s = useStyles(styles);
  const region = useRegion();
  const confirmationDialog = useDialog('confirmation');
  const currencySymbol = region.financial.currency.symbol;
  const { loadingIndicatorOn, loadingIndicatorOff } = useModelActions(
    uiModel
  ) as any;

  const getSyncTotals = async () => {
    try {
      const res = await wingsXero(
        {
          invoice_ids: selectedInvoices,
          payment_ids: selectedPayments
        },
        'ThirdPartyServiceXero::getSyncTotals'
      );
      return res as GetSyncTotalsResponse;
    } catch (error) {
      errorDialog.open(error as Error);
    }
  };

  const openResultDialog = ({
    syncedInvoices,
    syncedPayments
  }: {
    syncedInvoices: SyncInvoiceResponse | null;
    syncedPayments:
      | (SyncPaymentResponse & { failed: (string | number)[] })
      | null;
  }) => {
    const totalSynced =
      (syncedInvoices?.synced?.success?.length ?? 0) +
      (syncedPayments?.synced.length ?? 0);
    const totalFailed =
      (syncedInvoices?.synced?.failed?.length ?? 0) +
      (syncedPayments?.failed.length ?? 0);

    confirmationDialog.open({
      title: 'Sync Status',
      message: (
        <>
          {totalSynced > 0 && (
            <DialogBody>
              <Body bold dark is='span'>
                {totalSynced} transaction(s)
              </Body>{' '}
              have synced successfully
            </DialogBody>
          )}
          {totalFailed > 0 && (
            <DialogBody>
              <Body bold dark is='span'>
                {totalFailed} transaction(s)
              </Body>{' '}
              have failed to sync
            </DialogBody>
          )}
        </>
      ),
      hideCancel: true,
      buttonColor: 'light',
      confirmText: 'Close',
      onConfirm: () => null
    });
  };

  return (
    <Box width='100%'>
      <Box justifyContent='space-between' alignItems='center'>
        <p {...s('title')}>Sync Invoices</p>
        {(hasInvoicesOrPayments || isLoading) && (
          <Box flexDirection='row' spacing={10}>
            <DefaultButton
              dark
              isDisabled={isLoading}
              onClick={() => {
                getInvoices(true);
                getPayments(true);
              }}
            >
              <Icon
                type={ICONS.REFRESH}
                style={{ display: 'flex', marginRight: 8 }}
              />
              Refresh
            </DefaultButton>
            <DefaultButton
              red={hasInvoicesOrPayments && !isLoading}
              isDisabled={!hasInvoicesOrPayments || isLoading}
              onClick={async () => {
                if (
                  selectedInvoices.length === 0 &&
                  ignoredInvoices.length === 0 &&
                  selectedPayments.length === 0
                ) {
                  confirmationDialog.open({
                    title: 'Error',
                    message:
                      'You must select at least one or more invoices or payments before syncing with Xero',
                    hideCancel: true,
                    buttonColor: 'light',
                    confirmText: 'Close'
                  });
                  return;
                }
                loadingIndicatorOn({ message: 'Loading...' });
                const totals = await getSyncTotals();
                loadingIndicatorOff();
                confirmationDialog.open({
                  title: 'Confirm',
                  confirmText: 'Sync',
                  onConfirm: async () => {
                    let syncedInvoices: SyncInvoiceResponse | null = null;
                    let syncedPayments:
                      | (SyncPaymentResponse & { failed: (string | number)[] })
                      | null = null;
                    if (selectedInvoices.length > 0) {
                      syncedInvoices = await syncInvoices();
                    }
                    if (selectedPayments.length > 0) {
                      syncedPayments = await syncPayments();
                    }
                    // It uses the same dialog so we need to wait for it to close
                    setTimeout(
                      () =>
                        openResultDialog({ syncedInvoices, syncedPayments }),
                      200
                    );
                  },
                  message: (
                    <>
                      <Body is='span' semibold dark>
                        Summary
                      </Body>{' '}
                      <Box flexDirection='column' spacing={10} mt={10} ml={5}>
                        <DialogBody>
                          <Body is='span' semibold dark>
                            &middot;{' '}
                            {selectedInvoices.length + selectedPayments.length}{' '}
                            transaction(s)
                          </Body>{' '}
                          will be synced.
                        </DialogBody>
                        <DialogBody>
                          <Body is='span' semibold dark>
                            &middot; {currencySymbol}
                            {formatCurrency(
                              totals?.total_export_amount ?? 0,
                              2
                            )}
                          </Body>{' '}
                          will be processed into Xero and {currencySymbol}
                          <Body is='span' semibold dark>
                            {formatCurrency(
                              totals?.total_import_amount ?? 0,
                              2
                            )}
                          </Body>{' '}
                          will be imported from Xero.
                        </DialogBody>
                        <DialogBody>
                          <Body is='span' semibold dark>
                            &middot;{' '}
                            {ignoredInvoices.length + ignoredPayments.length}{' '}
                            transaction(s)
                          </Body>{' '}
                          will be ignored.
                        </DialogBody>
                      </Box>
                    </>
                  )
                });
              }}
            >
              Sync
            </DefaultButton>
          </Box>
        )}
      </Box>
      <HorizontalDivider />
      <Box justifyContent='space-between' alignItems='center' mb={30}>
        <Body dark normal>
          Export Invoices from Rex into Xero and import reconciled invoices back
          into Rex.
        </Body>
        {lastSynced && !isLoading && (
          <Body small light normal regular>
            Data last updated {dayjs.unix(+lastSynced).fromNow()}
          </Body>
        )}
      </Box>
    </Box>
  );
};

export default SyncInvoicesHeader;
