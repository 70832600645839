import Box from '@rexlabs/box';
import { useModelState } from '@rexlabs/model-generator';
import DownloadCell from 'components/record-list-screen/cells/download-cell';
import { RecordListTable } from 'components/record-list-screen/table';
import { ColumnConfig } from 'components/record-list-screen/types';
import { Body } from 'components/text/body';
import { Link as StyledLink } from 'components/text/link';
import React, { FC, useMemo } from 'react';
import Icon, { ICONS } from 'shared/components/icon';
import AlertBanner from 'src/view/components/alert-banner';
import { DefaultButton } from 'src/view/components/button';
import sessionModel from 'data/models/custom/session';
import { Link } from '@rexlabs/whereabouts';

interface GeneratedInvoice {
  contact_name: string;
  agent_id: string;
  id: string;
}

interface DownloadGeneratedInvoicesProps {
  invoices: GeneratedInvoice[];
  downloadInvoice: (id: string) => void;
  downloadAllInvoices: () => void;
  sendAllClick: () => void;
  mode?: 'draft';
  closeDialog?: () => void;
}

const DownloadGeneratedInvoices = ({
  invoices,
  downloadInvoice,
  downloadAllInvoices,
  sendAllClick,
  mode,
  closeDialog
}: DownloadGeneratedInvoicesProps) => {
  const session = useModelState(sessionModel);

  const hasXero = useMemo(() => {
    return session.third_party_extensions.some(
      (extension) => extension.service_type_id === 'xero'
    );
  }, [session.third_party_extensions]);

  const columns: ColumnConfig<GeneratedInvoice>[] = useMemo(
    () => [
      {
        id: 'contact_name',
        label: 'name',
        forced: true
      },
      {
        id: 'download',
        label: '',
        Cell: DownloadCell as FC,
        cellProps: {
          label: 'Download Invoice',
          onClick: (data: GeneratedInvoice) => downloadInvoice(data.id)
        },
        forced: true,
        width: 150
      }
    ],
    [downloadInvoice]
  );

  return (
    <Box
      flexDirection='column'
      spacing={20}
      p={'5px 10px 10px'}
      style={{ margin: 0 }}
    >
      {mode !== 'draft' && (
        <Box m={'0px 10px 10px'}>
          <AlertBanner
            type='silent'
            message='Invoices have been successfully generated'
          >
            <Body small dark>
              The invoices have been created and can be synced to your
              accounting software.{' '}
              {hasXero && (
                <Link to={{ config: { path: '/apps/xero/sync_invoices' } }}>
                  <StyledLink small dark>
                    Go to Sync Invoices.
                  </StyledLink>
                </Link>
              )}
            </Body>
          </AlertBanner>
        </Box>
      )}
      <Body regular dark>
        Download {mode !== 'draft' ? 'and send ' : ''}invoices to the selected
        agents.
      </Body>
      <Box mb={40}>
        <RecordListTable
          items={invoices}
          columns={columns}
          visibleColumns={columns.map((c) => c.id)}
          hasSelection={false}
          LoadingView={() => null}
          EmptyView={() => null}
          variant={'compact'}
        />
      </Box>
      <Box
        flexDirection='row'
        justifyContent='flex-end'
        spacing={10}
        p={'0 10px 10px'}
      >
        {mode === 'draft' ? (
          <DefaultButton light onClick={closeDialog}>
            Close
          </DefaultButton>
        ) : (
          <>
            <DefaultButton red onClick={downloadAllInvoices}>
              Download All
            </DefaultButton>
            <DefaultButton
              blue
              IconLeft={() => (
                <Icon
                  type={ICONS.LETTER}
                  style={{
                    display: 'flex',
                    margin: '0 6px',
                    width: 16,
                    height: 16
                  }}
                />
              )}
              onClick={sendAllClick}
            >
              Send Invoices
            </DefaultButton>
          </>
        )}
      </Box>
    </Box>
  );
};

export default DownloadGeneratedInvoices;
