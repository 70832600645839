import Box from '@rexlabs/box';
import { MaximumPayableCommissionFormula } from 'features/commission-worksheet/components/max-payable-commission-formula';
import { RecordListTable } from 'components/record-list-screen/table';
import { Body } from 'components/text/body';
import { formatCurrency } from 'utils/formatters';
import dayjs from 'dayjs';
import AlertBanner from 'view/components/alert-banner';
import { Link } from 'components/text/link';
import React, { ChangeEvent, FC, useMemo } from 'react';
import { useRegion } from 'hooks/use-region';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import {
  AgentAllocation,
  CommissionTier,
  CommissionWorksheetItem
} from 'features/commission-worksheet/types/commission-worksheet';
import { UpstreamCommissionSummary } from 'data/models/entities/agent-commissions';
import { ColumnConfig } from 'components/record-list-screen/types';
import { useDialog } from 'hooks/use-dialog';
import PercentageInputCell from 'features/commission-worksheet/components/percentage-input-cell';
import { getSlidingScaleCommission } from 'features/commission-worksheet/utils/get-sliding-scale-commission';
import { sumBy } from 'lodash';
import { stripPrefixFromProperties } from 'shared/utils/prefix-hell';
import { ContractsItem } from 'features/listings/data/contracts/types';

const LISTING_REACHED_CALCULATION_DATE_MESSAGE =
  'This listing has reached it’s calculation date and the amount has been based on the agent’s earnings to date at that point in time.';

const TOTAL_COMMISSION_ESTIMATE_BASIS_MESSAGE =
  'The estimated total commission amount has been calculated based on the ' +
  "agent's current earnings to date, placing them in the tiers above.";

const styles = StyleSheet({
  total: {
    borderBottom: `6px double #dbdbd6`
  }
});

interface AgentSlidingScaleCommissionsProps {
  worksheet: CommissionWorksheetItem;
  worksheetVersionId: string;
  agentAllocation: AgentAllocation;
  commissionSummary: UpstreamCommissionSummary | null;
  distributableCommissions: number;
  availableCommission: number;
  onChange: (name: string, value: unknown, silent?: boolean) => void;
}
export const AgentSlidingScaleCommissions = ({
  worksheetVersionId,
  agentAllocation,
  commissionSummary,
  distributableCommissions,
  availableCommission,
  onChange,
  worksheet
}: AgentSlidingScaleCommissionsProps) => {
  const s = useStyles(styles);
  const currencySymbol = useRegion().financial.currency.symbol;
  const commissionBreakdown = useDialog('commissionBreakdown');

  const columns: ColumnConfig<CommissionTier>[] = useMemo(
    () => [
      {
        id: 'tier',
        label: 'sliding commission tier',
        forced: true,
        width: '50%',
        selector: (row) => {
          // Sample output $0 - $100, $100 and over
          return `${currencySymbol}${formatCurrency(
            row.sliding_structure_tier.start_of_range
          )} ${
            Number(row.sliding_structure_tier?.end_of_range)
              ? `- ${currencySymbol}${formatCurrency(
                  row.sliding_structure_tier.end_of_range
                )}`
              : 'and over'
          }`;
        }
      },
      {
        id: 'listing_amount_percentage',
        label: 'amount to agent',
        forced: true,
        width: '30%',
        Cell: PercentageInputCell as FC,
        cellProps: {
          fullWidth: true,
          valueKey: 'percent',
          onChange: (e: ChangeEvent<HTMLInputElement>, data) => {
            if (!commissionSummary || !agentAllocation.sliding_scale_tiers)
              return;

            // Update percent for the tier
            const tiers = agentAllocation.sliding_scale_tiers.map((tier) =>
              tier.sliding_structure_tier.id === data.sliding_structure_tier.id
                ? {
                    ...tier,
                    percent: e.target.value
                  }
                : tier
            );

            // Calculate new tier commissions
            const tierAllocations = getSlidingScaleCommission(
              availableCommission,
              commissionSummary.summary.total_amount,
              commissionSummary.calculation_base.id,
              tiers
            );

            onChange(
              'sliding_scale_tiers',
              tierAllocations.map((tier) => ({
                sliding_structure_tier: tier.sliding_structure_tier,
                amount: tier.amount,
                percent: tier.percent
              }))
            );
          }
        }
      },
      {
        id: 'amount',
        label: `${currencySymbol} amount`,
        forced: true,
        rightAlign: true,
        cellProps: {
          justifyContentEnd: true
        },
        selector: (row) => {
          const allocation = agentAllocation.sliding_scale_tiers?.find(
            (tier) =>
              tier.sliding_structure_tier.id === row.sliding_structure_tier.id
          );

          if (!allocation) return '';

          // Display the current allocation amount
          return `${currencySymbol}${formatCurrency(
            Number(allocation.amount),
            2
          )}`;
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [agentAllocation, commissionSummary]
  );

  const totalSlidingCommission = useMemo(() => {
    if (!agentAllocation.sliding_scale_tiers) return 0;
    return sumBy(agentAllocation.sliding_scale_tiers, (tier) =>
      Number(tier.amount)
    );
  }, [agentAllocation.sliding_scale_tiers]);

  const commissionSummaryEndDateIsTodayOrPast = useMemo(() => {
    if (!commissionSummary || !worksheet) return false;

    const contract = stripPrefixFromProperties(
      worksheet.contract
    ) as ContractsItem;

    const statusDateMapping = {
      settled: contract.date_actual_settlement,
      unconditional: contract.date_actual_unconditional,
      deposit_release: contract.date_actual_deposit_release,
      agent_comm_payment: contract.date_actual_agent_comm_payment
    };

    const relevantDate =
      statusDateMapping[commissionSummary.calculation_listing_status.id];

    const today = dayjs();

    if (relevantDate) return dayjs(relevantDate).isSameOrBefore(today);
    return false;
  }, [commissionSummary, worksheet]);

  return (
    <>
      {worksheetVersionId === '3' ? null : (
        <Box mb={15}>
          <MaximumPayableCommissionFormula
            currencySymbol={currencySymbol}
            distributableCommissions={distributableCommissions}
            agentAllocation={agentAllocation}
            availableCommission={availableCommission}
          />
        </Box>
      )}
      <RecordListTable
        items={agentAllocation?.sliding_scale_tiers ?? []}
        columns={columns}
        visibleColumns={columns.map((c) => c.id)}
        setVisibleColumns={() => null}
        hasSelection={false}
        setOrderBy={() => null}
        isLoading={false}
        LoadingView={() => null}
        EmptyView={() => null}
        variant={'compact'}
        colorScheme={'light'}
      />
      <Box
        ml={'50%'}
        mb={20}
        p={'15px 10px 15px 10px'}
        justifyContent='space-between'
        alignItems='center'
        {...s('total')}
      >
        <Body dark small semibold>
          TOTAL AMOUNT
        </Body>
        <Body dark semibold>
          {currencySymbol}
          {formatCurrency(totalSlidingCommission, 2)}
        </Body>
      </Box>
      <AlertBanner
        type={commissionSummaryEndDateIsTodayOrPast ? 'disabled' : 'warning'}
        message={
          commissionSummaryEndDateIsTodayOrPast
            ? LISTING_REACHED_CALCULATION_DATE_MESSAGE
            : TOTAL_COMMISSION_ESTIMATE_BASIS_MESSAGE
        }
      >
        <Body small dark style={{ marginTop: -5 }}>
          Click below to see what listings have been included in their earnings
          to date.
        </Body>
        <Link
          small
          dark
          style={{ fontWeight: 500 }}
          onClick={() => commissionBreakdown.open({ commissionSummary })}
        >
          View commission breakdown
        </Link>
      </AlertBanner>
    </>
  );
};
