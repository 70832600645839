import React, { useCallback } from 'react';
import { capitalize } from 'lodash';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { COLORS } from 'theme';
import Box from '@rexlabs/box';
import { Column, Grid } from 'shared/components/grid';
import { TrackReminder } from './types/reminder';
import SortableList from 'src/view/components/sortable-dnd';
import { arrayMove } from '@dnd-kit/sortable';
import Icon, { ICONS } from 'shared/components/icon';
import { CellActionMenu } from 'components/record-list-screen/components/cell-action-menu';

const styles = StyleSheet({
  container: {
    position: 'relative',
    zIndex: 0
  },
  header: {
    backgroundColor: COLORS.PRIMARY.INFORMATIVE,
    margin: '0 !important',
    '& p': {
      color: '#fff',
      padding: '6px 0',
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '12px'
    }
  },
  row: {
    alignItems: 'center',
    '& p': {
      color: COLORS.GREY_DARK
    },
    '& span': {
      fontWeight: 600
    }
  },
  iconContainer: {
    position: 'relative'
  },
  badge: {
    position: 'absolute',
    bottom: '-3px',
    right: '6px'
  }
});

interface TrackRemindersProps {
  reminders: TrackReminder[];
  onChange: (sortedReminders: TrackReminder[]) => void;
  onEdit: (reminder: TrackReminder) => void;
  onDelete: (reminder: TrackReminder) => void;
  disableSort?: boolean;
}

function TrackReminders({
  reminders,
  onChange,
  onEdit,
  onDelete,
  disableSort
}: TrackRemindersProps) {
  const s = useStyles(styles);
  const items = reminders.map((reminder) => ({
    ...reminder,
    id: reminder._id
  }));

  const renderItem = useCallback(
    (reminder: TrackReminder, index: number) => {
      return (
        <SortableList.Item key={reminder.id} id={reminder.id}>
          <Grid
            columns={5}
            p={'12px 0'}
            style={{
              backgroundColor:
                index % 2 === 0 ? 'transparent' : COLORS.BACKGROUNDS.SAND_LIGHT
            }}
            {...s('row')}
          >
            {!disableSort && (
              <Column
                width={0.3}
                flexDirection='row'
                justifyContent='center'
                alignItems='center'
              >
                <SortableList.DragHandle
                  tooltip={() => 'Drag and drop to reorder reminders'}
                />
              </Column>
            )}
            <Column width={0.3} {...s('iconContainer')}>
              <Icon type={ICONS.TRACKS[reminder.reminder_type.toUpperCase()]} />
              {reminder.reminder_template && (
                <Icon {...s('badge')} type={ICONS.TRACKS.TEMPLATE_BADGE} />
              )}
            </Column>
            <Column
              width={2.6}
              flexDirection='row'
              justifyContent='space-between'
              alignItems='center'
              flexWrap='nowrap'
            >
              <p>
                <span>{capitalize(reminder._reminder_type)}</span>{' '}
                {reminder.reminder_description}
              </p>
              <CellActionMenu
                data={reminder}
                items={[
                  {
                    label: 'Edit',
                    onClick: () => onEdit(reminder)
                  },
                  {
                    label: 'Delete',
                    onClick: () => onDelete(reminder)
                  }
                ]}
              />
            </Column>
            <Column width={0.9}>
              <p>
                {reminder.reminder_due_interval === null &&
                reminder.reminder_due_period
                  ? 0
                  : reminder.reminder_due_interval}{' '}
                {reminder.reminder_due_period}
              </p>
            </Column>
            <Column width={0.9}>
              <p>{reminder.remindee._display}</p>
            </Column>
          </Grid>
        </SortableList.Item>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [disableSort]
  );

  function onDragEnd(oldIndex: number, newIndex: number) {
    return onChange(
      arrayMove(items, oldIndex, newIndex).map((item, index) => ({
        ...item,
        sort_order: index + 1
      }))
    );
  }

  return (
    <Box {...s('container')}>
      <Grid columns={5} {...s('header')}>
        {!disableSort && (
          <Column width={0.3}>
            <p></p>
          </Column>
        )}
        <Column width={0.4}>
          <p>type</p>
        </Column>
        <Column width={2.5}>
          <p>reminder</p>
        </Column>
        <Column width={0.9}>
          <p>remind in</p>
        </Column>
        <Column width={0.9}>
          <p>assign to</p>
        </Column>
      </Grid>
      <SortableList<TrackReminder & { id: number }>
        items={items}
        onDragEnd={onDragEnd}
        renderItem={renderItem}
      />
    </Box>
  );
}

export default TrackReminders;
